import { Component, Input } from "@angular/core";
import { AuthService } from "./shared/services/auth.service.service";
import { NgxPermissionsService } from "ngx-permissions";
import { ApiAuthService } from "./shared/services/api-auth.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from "./admin/containers/trainings/format-datepicker";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
  ],
})
export class AppComponent {
  title = "vqingenieria";
  @Input() nav;
  sideBarIsOpened = false;

  constructor(
    public auth: AuthService,
    private api: ApiAuthService,
    private permissionsService: NgxPermissionsService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("es");
  }

  async ngOnInit() {
    this.sideBarIsOpened = false;

    if (this.auth.isAuthenticated()) {
      if (localStorage.getItem("permissions")) {
        await this.permissionsService.loadPermissions(
          JSON.parse(localStorage.getItem("permissions"))
        );
      }

      await this.api
        .getByAuth()
        .then(async (res: any) => {
          localStorage.setItem("role", res.permis.unique_id);
          const permisos = [];
          for (
            let index = 0;
            index < res.permis.PermissionModels.length;
            index++
          ) {
            permisos.push(res.permis.PermissionModels[index].slug);
          }
          await this.permissionsService.loadPermissions(permisos);
          localStorage.setItem("permissions", JSON.stringify(permisos));
        })
        .catch((err) => {});
    }
  }

  toggleSideBar(shouldOpen: boolean) {
    this.sideBarIsOpened = !this.sideBarIsOpened;
  }
}
