import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";


import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { PageNotFoundComponent } from "./shared/containers/page-not-found/page-not-found.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";

import { SharedModule } from "./shared/shared.module";

import { MaterialModule } from "./material/material.module";
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgImageSliderModule } from "ng-image-slider";
@NgModule({
  declarations: [AppComponent, PageNotFoundComponent,],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgImageSliderModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    SharedModule.forRoot(),
    NgxPermissionsModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
