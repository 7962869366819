import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";

@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService) {}

  public isAuthenticated(): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem("token");
    try {
      if (this.jwtHelper.isTokenExpired(token))
      {
        localStorage.clear();
        return false;
      }
      else 
        return true;
    } catch (e) {
      localStorage.clear();
      return false;
    }
  }

  public setLogin(token: string): void {
    localStorage.setItem("token", token);
  }
}
