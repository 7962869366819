import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root"
})
export class ApiAuthService {
  constructor(private http: HttpClient) {}

  getByAuth() {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiurl + "/user/get-user-by-auth", {
          headers: new HttpHeaders().set(
            "Authorization",
            "Bearer " + localStorage.getItem("token")
          )
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
