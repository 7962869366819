import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuardService } from "./services/auth-guard.service";
import { AuthService } from "./services/auth.service.service";
import { JwtModule } from "@auth0/angular-jwt";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { NgxPermissionsModule } from "ngx-permissions";
@NgModule({
  imports: [CommonModule,NgxPermissionsModule.forChild()],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  // exports: [NgxPermissionsModule]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [AuthGuardService, AuthService, JwtModule]
    };
  }
}
